

const AgamNeeds = () =>{
    return (
        <>
        
        <div className="needsbg">
            <div className='container needcontent' >
                <h4 className='needhead' >Why You Need Sri Agam Mithran ?</h4>
                <p className='needpara'>Uses of Enterprise Resource Planning (ERP) in Healthcare Industry makes it easier for professionals to navigate the dynamic landscape of healthcare operations. Integrating Enterprise Resource Planning (ERP) is instrumental in transforming how organizations manage their functions. 
                </p>
            </div>
        </div>
        </>
        );
}

export default AgamNeeds