
import MyNavbar from "../../component/Navbar/Navbar";
import Contact from "../../component/contact/Contact";
import Footer from "../../component/Footer/Footer"
const Contactus = () => 
{
    return(
        <>
        <MyNavbar/>
        <Contact/>
        <Footer/>
        </>
    )
}

export default Contactus;