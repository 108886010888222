

const TempleNeeds = () =>{
    return (
        <>
        
        <div className="needsbg">
            <div className='container needcontent' >
                <h4 className='needhead' >Why You Need a Temple erp?</h4>
                <p className='needpara'>Try our Temple Management Software today and see how it can revolutionize your temple management. In addition, our software is affordable and provides a great return on investment.
                </p>
            </div>
        </div>
        </>
        );
}

export default TempleNeeds